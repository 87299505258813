
import Vue from 'vue'

export default Vue.extend( {
    name: 'Main',

    data: function(): any {
        return {
            imageSrc: "images/now.jpg"
        }
    },

    mounted: function(): void {
        setInterval( () => {
            const randomQueryParam = `?_=${Math.random()}`;
            this.imageSrc = "images/now.jpg" + randomQueryParam;
            console.log( this.imageSrc );
        }, 10000 );
    },

    methods: {}
} )
